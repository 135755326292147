import React, { useState, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid, IconButton, Tooltip, Dialog, DialogContent, DialogActions, Button, Autocomplete } from '@mui/material';
import useStyles from "../styles";

//components
import SectionTitle from '../../Titles/SectionTitle';
import { ConfirmationDialog } from '../../Dialog/ConfirmationDialog';

//icons
import { FileUploadOutlined as UploadIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';

//Context
import { usePatrollerData } from "../../../context/PatrollerContext";

//themes
import themes from '../../../theme/defaultTheme';

// local database
import { saveRequest } from '../../../db';

export const AddImages = () => {
    const { FormLabel, StyledTextField, ImgActions, StyledDialogTitle, StyledDialogButton } = useStyles();
    const { incidentDrpDwnValues } = usePatrollerData();
    const { control, setValue, getValues } = useFormContext();
    const [images, setImages] = useState(getValues('images') || Array(6).fill([]));
    const [open, setOpen] = useState(false);
    const [imagesToPreview, setImagesToPreview] = useState([]);
    const [imgToDelete, setImgToDelete] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    //drop down values
    const imageOptions = useMemo(() => {
        if (incidentDrpDwnValues && Object.keys(incidentDrpDwnValues).length > 0)
            return incidentDrpDwnValues.imageTypes || [];
    }, [incidentDrpDwnValues]);

    //Upload image.
    const handleFileChange = async (event, index) => {
        const files = Array.from(event.target.files);
        const currentType = images[index].length > 0 ? images[index][0].type : "";

        const filePromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve({
                        file: reader.result, // base64 string
                        fileName: file.name,
                        previewUrl: URL.createObjectURL(file), // are preview URLs necessary now?
                        type: currentType,
                    });
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        try {
            const newImages = await Promise.all(filePromises);
            setImages((prevImgs) => {
                const newImgs = [...prevImgs];
                newImgs[index] = newImages;
                return newImgs;
            });
            setValue(`images[${index}]`, newImages);
            saveRequest(
                "/patroller/postAddIncident",
                getValues(),
                "pi0",
                "tempFormData"
            );
        } catch (error) {
            console.error("Error reading file:", error);
        }
    };

    const handleTypeChange = (value, index) => {
        let newImgs;
        setImages((prevImgs) => {
            newImgs = [...prevImgs];

            if (newImgs[index].length > 0) {
                newImgs[index] = newImgs[index].map((img) => ({
                    ...img,
                    type: value,
                }));
            } else newImgs[index] = [{ type: value }];
            return newImgs;
        });
        setValue(`images[${index}]`, newImgs[index]);
        saveRequest(
            "/patroller/postAddIncident",
            getValues(),
            "pi0",
            "tempFormData"
        );
    };

    const confirmDeleteImage = () => {
        setImages((prevImgs) => {
            const newImgs = [...prevImgs];
            newImgs[imgToDelete] = [];
            return newImgs;
        });
        setValue(`images[${imgToDelete}]`, []);
        setImgToDelete(null);
        setIsDialogOpen(false);
        saveRequest(
            "/patroller/postAddIncident",
            getValues(),
            "pi0",
            "tempFormData"
        );
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleOpenPreview = (index) => {
        setOpen(true);
        if (images[index]?.length > 0) {
            setImagesToPreview(images[index].map(img => img.file));
        }
    };

    const handleClosePreview = () => {
        setOpen(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionTitle title="Images" />
            </Grid>

            <Grid item xs={12}>
                <form>
                    {images.map((img, index) => (

                        <Grid container key={index} style={{ paddingBottom: "15px" }}>
                            <Grid item xs={6} sm={3} md={3} lg={3}>
                                <FormLabel>Image(s) {index + 1}</FormLabel>
                            </Grid>

                            {/* Type drop down */}
                            <Grid item xs={6} sm={3} md={3} lg={3}>
                                <Controller
                                    name={`images[${index}][0].type`}
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            value={field.value || null}
                                            options={imageOptions || []}
                                            getOptionLabel={(option) => option || ""}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            renderInput={(params) => (
                                                <StyledTextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Select type"
                                                    fullWidth
                                                />
                                            )}
                                            onChange={(event, value) => handleTypeChange(value, index)}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* Upload image button */}
                            <ImgActions item>
                                <input
                                    accept="image/*"
                                    capture="environment"
                                    style={{ display: 'none' }}
                                    id={`upload-img-${index}`}
                                    type="file"
                                    multiple
                                    onChange={event => handleFileChange(event, index)}
                                />
                                <label htmlFor={`upload-img-${index}`}>
                                    <Tooltip title="Click to upload image">
                                        <IconButton component="span" aria-label="upload image">
                                            <UploadIcon style={{ color: themes.default.palette.primary.main }} />
                                        </IconButton>
                                    </Tooltip>
                                </label>
                            </ImgActions>

                            {/* Delete uploaded image button */}
                            {img?.length > 0 && img[0]?.file && (
                                <ImgActions item>
                                    <Tooltip title="Delete uploaded image">
                                        <IconButton onClick={() => { setIsDialogOpen(true); setImgToDelete(index) }} aria-label="delete image">
                                            <DeleteIcon style={{ color: themes.default.palette.error.main }} />
                                        </IconButton>
                                    </Tooltip>
                                </ImgActions>
                            )}

                            {/* Image file name */}
                            <ImgActions item >
                                <Controller
                                    name={`images[${index}]`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Tooltip title="Click to preview image">
                                            <div>
                                                <Button
                                                    disabled={!img.length || !img[0].file}
                                                    onClick={() => handleOpenPreview(index)}
                                                    aria-label="preview image"
                                                    style={{ textTransform: "none", marginTop: "2px" }}>
                                                    {!img.length || !img[0].file ? 'No file(s) uploaded'
                                                        : `${img.length} file(s) uploaded`}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    )}
                                />
                            </ImgActions>
                        </Grid>
                    ))}
                </form>
            </Grid>

            {/* Delete Confirmation Dialog */}
            <ConfirmationDialog
                open={isDialogOpen}
                title="Delete Confirmation"
                content="Are you sure you want to delete uploaded image(s)?"
                onConfirm={confirmDeleteImage}
                onClose={handleCloseDialog}
            />

            {/* Preview image Dialog */}
            <Dialog open={open} onClose={handleClosePreview}>
                <StyledDialogTitle>Preview</StyledDialogTitle>
                <DialogContent>
                    {imagesToPreview.length > 0 && imagesToPreview.map((file, index) => (
                        <img key={index}
                            src={file}
                            alt={`Preview image ${index + 1}`}
                            style={{ width: '100%', marginBottom: '10px' }} />
                    ))}
                </DialogContent>
                <DialogActions>
                    <StyledDialogButton onClick={handleClosePreview} style={{ textTransform: "None" }}>
                        Close
                    </StyledDialogButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};
