import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useStyles from "./styles";
import { HighlightOff as CancelIcon } from "@mui/icons-material";


//constants
import { ApiEndpoints } from "../../utils/Constants";

//context
import { useAuth } from "../../context/AuthContext";

//custom hooks
import { useFetch } from "../../hooks/useFetch";
import { SinglePageForm } from "../../components/Patroller/SinglePageForm";

//idb
import { deleteRequest } from "../../db";

export default function AddIncident() {
    const { StyledButton } = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [existingIncidentData, setExistingIncidentData] = useState(null);
    const { logout } = useAuth();
    const postFetcher = useFetch("post");

    //fetch data if exisiting incident present
    useEffect(() => {
        if (location?.state?.incident)
            setExistingIncidentData(location.state.incident);
    }, []);

    //on cancel button click
    const handleCancelClick = async () => {

        //delete cached form data on cancel
        deleteRequest({ id: "pi0" }, 'tempFormData')

        //for assigned incident, change status
        if (existingIncidentData && existingIncidentData.status.toLowerCase() === "assigned") {
            const updatedData = {
                ...existingIncidentData,
                "callInTime": existingIncidentData.callInTime ? new Date(existingIncidentData.callInTime) : "",
                "f1017": existingIncidentData.f1017 ? new Date(existingIncidentData.f1017) : "",
                "shiftId": JSON.parse(sessionStorage.getItem('user'))?.shift?.id || null,
                "status": "open"
            }
            try {
                const response = await postFetcher.setRequest({
                    endpoint: "/patroller" + ApiEndpoints["API_POST_EDIT_INCIDENT"],
                    body: JSON.stringify(updatedData)
                });
                if (response?.error && (response.errorStatus === 403 || response.errorStatus === 401)) //logout if forbidden or unauthorized
                    logout();
            }
            catch (error) {
                console.error('Error editing incident during cancel:', error)
            }
        }
        navigate('/patroller/view-open-incidents');
    }

    return (
        <Grid container>
            <Grid item xs={12} >
                <StyledButton sx={{ marginLeft: 2, marginBottom: 0, marginTop: 2 }} startIcon={<CancelIcon />} onClick={handleCancelClick}>Cancel</StyledButton>
            </Grid>
            <Grid item xs={12}>
                <SinglePageForm existingIncidentData={existingIncidentData} />
            </Grid>
        </Grid>
    );
}