import React from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Alert, Grid } from "@mui/material";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

//components
import { RecenterButton } from './RecenterButton';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41], // size of the icon
    shadowSize: [41, 41], // size of the shadow
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    shadowAnchor: [14, 41],  // the same for the shadow
    popupAnchor: [1, -34] // point from which the popup should open relative to the iconAnchor
});

L.Marker.prototype.options.icon = DefaultIcon;

const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

export const IncidentLocationMap = (props) => {
    const { latitude, longitude, setValue, setShowLocation } = props;

    const handleMarkerDragEnd = (event) => {
        const latlng = event.target.getLatLng();
        const lat = latlng.lat;
        const lng = latlng.lng;
        setValue('location.latitude', lat);
        setValue('location.longitude', lng);
        setShowLocation(true);
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginLeft: "14px" }}>
                <Alert severity="info">Move pin around the map to change location.</Alert>
            </Grid>
            <Grid item xs={12}>
                <MapContainer
                    center={latitude && longitude ? [latitude, longitude] : [0, 0]}
                    style={{ height: "280px", marginLeft: "14px" }}
                    minZoom={6}
                >
                    <ChangeView center={[latitude, longitude]} zoom={15} />
                    <TileLayer
                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    <Marker
                        position={[latitude, longitude]}
                        draggable={true}
                        eventHandlers={{ dragend: handleMarkerDragEnd }}
                    >
                        <Popup>You are here</Popup>
                    </Marker>
                    <RecenterButton lat={latitude} lng={longitude} zoomLevel={15} />
                </MapContainer>
            </Grid>
        </Grid>
    );
};