import { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Select,
    MenuItem,
    Typography
} from "@mui/material";
import useStyles from "./styles";

//constants
import { ManagerConstants } from "../../utils/Constants";

export default function GenerateMonthlyReportDialog({ open, onClose, onGenerateClick }) {
    const { StyledDialogButton } = useStyles();
    const [monthList, setMonthList] = useState([]);
    const [selectedMonthId, setSelectedMonthId] = useState();

    useEffect(() => {
        const monthList = ManagerConstants.MONTH_LIST;
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        const updatedMonthList = monthList.map(month => {

            //if selected month is before current month, pick current year
            const reportYear = month.id < currentMonth ? currentYear : currentYear - 1;
            return {
                id: month.id,
                name: `${month.name} ${reportYear}`
            };
        });
        setMonthList(updatedMonthList);
        if (updatedMonthList?.length > 0) {
            setSelectedMonthId(updatedMonthList[0].id);
        }
    }, []);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogContent sx={{ paddingTop: "40px", paddingBottom: "40px" }}>

                {/* Select month */}
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={{
                            fontWeight: 'bold',
                            marginTop: "14px"
                        }}>
                            Select month:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            value={selectedMonthId}
                            onChange={(event) => { setSelectedMonthId(event.target.value) }}
                            fullWidth
                        >
                            {monthList?.length > 0 && monthList.map(month => (
                                <MenuItem key={month.id} value={month.id}>
                                    {month.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <StyledDialogButton onClick={() => onClose()}>Cancel</StyledDialogButton>
                <StyledDialogButton onClick={() => onGenerateClick({ selectedMonthId })}>Generate</StyledDialogButton>
            </DialogActions>
        </Dialog >
    );
}