const HeaderConstants = {
  APPLICATION_TITLE: "DCSO Courtesy Patrol Management",
  END_SHIFT_BTN: "End Shift",
  LOGOUT_BTN: "Logout",
};
const PatrollerConstants = {
  VIEW_OPEN_INCIDENTS_BTN: "View Open Incidents",
  ADD_INCIDENT_BTN: "Add Incident",
  START_SHIFT_BTN: "Start Shift",
  REFRESH_MAP_BTN: "Reload Map",
  VEHICLE_REFUELING_BTN: "Vehicle Refuel",
  FUEL_TYPE: ["Gas", "Diesel"]
};
const GenericConstants = {
  CONTINUE_BTN: "Continue",
  SAVE_BTN: "Save",
  CANCEL_BTN: "Cancel",
  ROLES_LIST: ["Patroller", "Dispatcher", "Manager", "Admin"]
};
const ManagerConstants = {
  "SERVICE_CALL_ALL_COLUMNS": [
    {
      accessorKey: "status",
      header: "Status",
      size: 80
    },
    {
      accessorKey: "truckNumber",
      header: "Truck #",
      size: 80
    },
    {
      accessorKey: "phoneNumber",
      header: "Phone #",
      size: 80
    },
    {
      accessorKey: "primaryPatroller",
      header: "Primary Patroller",
      size: 100
    },
    {
      accessorKey: "otherPatrollers",
      header: "Other Patroller(s)",
      size: 120
    },
    {
      accessorKey: "lanesBlocked",
      header: "Lanes Blocked",
      size: 120
    },
    {
      accessorKey: "route",
      header: "Route",
      size: 100
    },
    {
      accessorKey: "freeway",
      header: "Freeway",
      size: 150
    },
    {
      accessorKey: "crossStreet",
      header: "Cross Street",
      size: 100
    },
    {
      accessorKey: "typeOfAssist",
      header: "Type of Assist",
      size: 80
    },
    {
      accessorKey: "serviceProvided",
      header: "Service Provided",
      size: 120
    },
    {
      accessorKey: "detectMethod",
      header: "Detect Method",
      size: 80
    },
    {
      accessorKey: "callInTime",
      header: "Call in Time",
      size: 80
    },
    {
      accessorKey: "f1017",
      header: "10-17",
      size: 80
    },
    {
      accessorKey: "startTime",
      header: "Start Time",
      size: 80
    },
    {
      accessorKey: "endTime",
      header: "End Time",
      size: 80
    },
    {
      accessorKey: "lastModified",
      header: "Last Modified",
      size: 80
    }
  ],
  "SERVICE_CALL_DEFAULT_PRIMARY_COLUMNS": [
    {
      accessorKey: "status",
      header: "Status",
      size: 80
    },
    {
      accessorKey: "truckNumber",
      header: "Truck #",
      size: 80
    },
    {
      accessorKey: "primaryPatroller",
      header: "Primary Patroller",
      size: 100
    },
    {
      accessorKey: "route",
      header: "Route",
      size: 100
    },
    {
      accessorKey: "detectMethod",
      header: "Detect Method",
      size: 80
    },
    {
      accessorKey: "lastModified",
      header: "Last Modified",
      size: 80
    }
  ],
  "CHART_COLOR_PALETTE": [
    '#a6cee3',
    '#b2df8a',
    '#1f78b4',
    '#cab2d6',
    '#33a02c',
    '#ff7f00',
    '#e31a1c',
    '#fdbf6f',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
    '#fb9a99',
  ],
  "MONTH_LIST": [
    { "id": 1, "name": "January" },
    { "id": 2, "name": "February" },
    { "id": 3, "name": "March" },
    { "id": 4, "name": "April" },
    { "id": 5, "name": "May" },
    { "id": 6, "name": "June" },
    { "id": 7, "name": "July" },
    { "id": 8, "name": "August" },
    { "id": 9, "name": "September" },
    { "id": 10, "name": "October" },
    { "id": 11, "name": "November" },
    { "id": 12, "name": "December" }
  ],
  "MAX_BATCH_SIZE": 250,
  "MAX_DAYS_PER_REQUEST": 3,
  "DEFAULT_NUM_OF_DAYS": 1
};

const AdminConstants = {
  "TRUCK_STATUS": [
    { value: "in service", label: "in service" },
    { value: "under maintenance", label: "under maintenance" },
    { value: "decommissioned", label: "decommissioned" }
  ],
  "STATUS": [
    { value: "active", label: "active" },
    { value: "inactive", label: "inactive" }
  ],
  "ROLES": [
    { value: "patroller", label: "patroller" },
    { value: "dispatcher", label: "dispatcher" },
    { value: "manager", label: "manager" },
    { value: "admin", label: "admin" }
  ]
}

const ApiEndpoints = {

  //login
  "API_LOGIN": "/login",

  //patroller
  "API_GET_SHIFT_DROP_DWNS": "/patroller/getShiftInfoDropDwns",
  "API_POST_SHIFT_START": "/patroller/postShiftStart",
  "API_POST_SHIFT_END": "/patroller/postShiftEnd",
  "API_POST_VEHICLE_REFUELING": "/patroller/postVehicleRefuelingData",
  "API_GET_PATROLLER_INCIDENTS": "/patroller/getPatrollerIncidents",
  "API_GET_LAST_OPEN_SHIFT": "/patroller/getLastOpenShift",

  //dispatcher
  "API_GET_DISPATCHER_INCIDENTS": "/dispatcher/getAllIncidents",
  "API_POST_DELETE_INCIDENT": "/dispatcher/postDeleteIncident",

  //manager
  "API_GET_SERVICE_CALL_BY_ID": "/manager/getServiceCallById",
  "API_GET_SERVICE_CALLS_BY_DATE_RANGE": "/manager/getServiceCallsByDateRange",
  "API_POST_EDIT_SERVICE_CALL": "/manager/postEditServiceCall",
  "API_POST_DELETE_SERVICE_CALL": "/manager/postDeleteServiceCall",
  "API_GET_SUMMARIES_DROP_DOWNS": "/manager/getSummariesDropDwns",
  "API_GENERATE_REPORT": "/manager/postGenerateReport",
  "API_GENERATE_MONTHLY_REPORT": "/manager/postGenerateMonthlyReport",

  //admin
  "API_GET_ALL_INFO": "/admin/getAllInformation",
  "API_ADD_ITEM": "/admin/postAddItem",
  "API_EDIT_ITEM": "/admin/postEditItem",
  "API_DELETE_ITEM": "/admin/postDelItem",

  //shared
  "API_GET_ALL_INCIDENT_DROP_DWNS": "/getAllIncidentDropDwns",
  "API_GET_INCIDENT_BY_ID": "/getIncidentById",
  "API_GET_ALL_ROUTES_INFO": "/getAllRoutesInfo",
  "API_POST_ADD_INCIDENT": "/postAddIncident",
  "API_POST_EDIT_INCIDENT": "/postEditIncident",
  "API_HEALTH": "/health"
}
export { HeaderConstants, PatrollerConstants, GenericConstants, ManagerConstants, AdminConstants, ApiEndpoints };