import React, { useState, useEffect, useMemo } from "react";
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Autocomplete,
    Grid,
    Tooltip,
    IconButton,
    FormControlLabel, Checkbox
} from "@mui/material";
import useStyles from "./styles";

//icons
import { FileUploadOutlined as UploadIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';

//components
import { ConfirmationDialog } from "./ConfirmationDialog";

export default function EditSelectedIncidentDialog({ open, onClose, dialogTitle, dialogData, dialogType, onSave, dropDownValues }) {
    const { StyledDialogButton, StyledLabel, StyledInputRequired, StyledDialogTitle, StyledAutocomplete, AdditionalInfoLabel } = useStyles();
    const [data, setData] = useState(dialogData);
    const [routeOptions, setRouteOptions] = useState([]);
    const [freewayOptions, setFreewayOptions] = useState([]);
    const [crossStreetOptions, setCrossStreetOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [previewIndex, setPreviewIndex] = useState(null);
    const [incidentDrpDwns, setIncidentDrpDwns] = useState({});

    //Set drop down values
    useEffect(() => {
        if (dropDownValues && Object.keys(dropDownValues).length > 0) {
            if (dropDownValues.incidentDrpDwnValues) {
                const dropDown = dropDownValues.incidentDrpDwnValues;
                setIncidentDrpDwns(dropDown);
            }
            if (dropDownValues.allRoutesInfo) {
                const dropDown = dropDownValues.allRoutesInfo;
                setRouteOptions(dropDown.map(route => ({
                    id: route.id,
                    name: route.name
                })));
            }
        }
    }, [dropDownValues]);

    const memoizedFreewayOptions = useMemo(() => {
        if (dropDownValues?.allRoutesInfo && data?.route) {
            const selectedRouteId = data.route.id;
            return dropDownValues.allRoutesInfo
                .filter(route => route.id === selectedRouteId)[0]
                ?.freeways?.map(fwy => ({
                    id: fwy.id,
                    name: fwy.name
                })) || [];
        }
        return [];
    }, [dropDownValues, data.route]);

    const memoizedCrossStreetOptions = useMemo(() => {
        if (dropDownValues?.allRoutesInfo && data?.route?.id && data?.freeway?.id) {

            // Find selected route
            const selectedRoute = dropDownValues.allRoutesInfo.find(route => route.id === data.route.id);
            if (selectedRoute) {
                // Find selected freeway within the route
                const selectedFreeway = selectedRoute.freeways.find(fwy => fwy.id === data.freeway.id);

                if (selectedFreeway) {
                    // Return the cross streets
                    return selectedFreeway.crossStreets.map(cs => ({
                        id: cs.id,
                        name: cs.name
                    })) || [];
                }
            }
        }
        return [];
    }, [dropDownValues, data.freeway]);

    useEffect(() => {
        setFreewayOptions(memoizedFreewayOptions);
    }, [memoizedFreewayOptions]);

    useEffect(() => {
        setCrossStreetOptions(memoizedCrossStreetOptions);
    }, [memoizedCrossStreetOptions]);


    const handleTimeLocationChange = (field, value) => {
        setData(prevData => {
            const updatedData = { ...prevData, [field]: value };
            if (dropDownValues?.allRoutesInfo) {
                if (field === "route") {
                    updatedData.freeway = null;
                    updatedData.crossStreet = null;
                } else if (field === "freeway") {
                    updatedData.crossStreet = null;
                }
            }

            return updatedData;
        });
    };

    const handleVehicleInfoChange = (field, value) => {
        setData(prevData => {
            const newSelectedVehicle = {
                ...prevData.selectedVehicle,
                [field]: value
            };

            //Reset gallonsGiven if fuel is no more selected
            if (field === 'serviceProvided' && !value.includes('Fuel')) {
                newSelectedVehicle.gallonsGiven = 0;
            }

            return {
                ...prevData,
                selectedVehicle: newSelectedVehicle
            };
        });
    };

    const validateTimeLocationFields = () => {
        const newErrors = {};
        if (!data.detectMethods)
            newErrors["detectMethods"] = true;
        if (!data.route)
            newErrors["route"] = true;
        if (!data.freeway)
            newErrors["freeway"] = true;
        if (!data.crossStreet)
            newErrors["crossStreet"] = true;
        if (!data.lanesBlocked || data.lanesBlocked.length === 0)
            newErrors["lanesBlocked"] = true;
        if (!data.travelDirections || data.travelDirections.length === 0)
            newErrors["travelDirections"] = true;

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return false;
        }
        return true;
    };

    const validateVehicleInfoFields = () => {
        const newErrors = {};
        if (!data['selectedVehicle']['typeOfAssist'])
            newErrors["typeOfAssist"] = true;
        if (!data['selectedVehicle']['serviceProvided'] || data['selectedVehicle']['serviceProvided'].length === 0)
            newErrors["serviceProvided"] = true;
        if (data['selectedVehicle']['serviceProvided'].map(svc => svc.name.toLowerCase()).includes("fuel")
            && (isNaN(data['selectedVehicle']['gallonsGiven']) || data['selectedVehicle']['gallonsGiven'] <= 0))
            newErrors["gallonsGiven"] = true;
        if (!data['selectedVehicle']['comments'])
            newErrors["comments"] = true;

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return false;
        }
        return true;
    };

    //for closing comments field
    const handleClosingCommentsChange = (field, value) => {
        setData(prevData => {
            return {
                ...prevData,
                [field]: value
            };
        });
    };

    const handleSave = () => {
        if ((dialogType === "timeLocation" && validateTimeLocationFields())
            || (dialogType === "vehicleInfo" && validateVehicleInfoFields())
            || (dialogType === "images")
            || (dialogType === "closingComments")) {
            onSave(data);
        }
    };

    //Upload image.
    const handleFileChange = (event, index) => {
        const files = Array.from(event.target.files);
        const currentType = data[index].length > 0 ? data[index][0].type : '';
        const newImages = files.map(file => ({
            file: file,
            fileName: file.name,
            previewUrl: URL.createObjectURL(file),
            type: currentType
        }));

        const newData = [...data];
        newData[index] = newImages;
        setData(newData);
    };

    //Triggers when type drop down changes.
    const handleTypeChange = (value, index) => {
        setData(prevImgs => {
            const newImgs = [...prevImgs];
            if (newImgs[index].length > 0) {
                newImgs[index] = newImgs[index].map(img => ({ ...img, type: value }));
            }
            else
                newImgs[index] = [{ type: value }];

            return newImgs;
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <StyledDialogTitle>Edit {dialogTitle}</StyledDialogTitle>
            {dialogType === "timeLocation" && (
                <DialogContent>
                    <Grid container>
                        {Object.keys(data).map((key) => (
                            <Grid container spacing={3} key={key} sx={{ padding: "14px" }}>
                                {key === "detectMethods" && (
                                    <>
                                        <Grid item xs={5}>
                                            <StyledLabel sx={{ marginTop: "15px" }}>Detect Method<em style={{ color: 'red' }}> *</em></StyledLabel>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <StyledAutocomplete
                                                value={data[key] || null}
                                                options={incidentDrpDwns[key] || []}
                                                getOptionLabel={(option) => option || ""}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                onChange={(event, value) => handleTimeLocationChange(key, value)}
                                                renderInput={(params) =>
                                                    <TextField {...params} variant="standard" error={!!errors[key]} sx={errors[key] ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}} />}
                                            />
                                            {errors[key] && <StyledInputRequired>Required field</StyledInputRequired>}
                                        </Grid>
                                    </>
                                )}
                                {key === "route" && (
                                    <>
                                        <Grid item xs={5}>
                                            <StyledLabel>Route<em style={{ color: 'red' }}> *</em></StyledLabel>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <StyledAutocomplete
                                                value={data[key] || null}
                                                options={routeOptions || []}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option?.name || ""}
                                                onChange={(event, value) => handleTimeLocationChange(key, value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" error={!!errors[key]} sx={errors[key]
                                                        ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}} />
                                                )}
                                            />
                                            {errors[key] && <StyledInputRequired>Required field</StyledInputRequired>}
                                        </Grid>
                                    </>
                                )}
                                {key === "freeway" && (
                                    <>
                                        <Grid item xs={5}>
                                            <StyledLabel>Freeway<em style={{ color: 'red' }}> *</em></StyledLabel>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <StyledAutocomplete
                                                value={data[key] || null}
                                                options={freewayOptions || []}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option?.name || ""}
                                                onChange={(event, value) => handleTimeLocationChange(key, value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" error={!!errors[key]} sx={errors[key]
                                                        ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}} />
                                                )}
                                            />
                                            {errors[key] && <StyledInputRequired>Required field</StyledInputRequired>}
                                        </Grid>
                                    </>
                                )}
                                {key === "crossStreet" && (
                                    <>
                                        <Grid item xs={5}>
                                            <StyledLabel>Cross Street<em style={{ color: 'red' }}> *</em></StyledLabel>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <StyledAutocomplete
                                                value={data[key] || null}
                                                options={crossStreetOptions || []}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option?.name || ""}
                                                onChange={(event, value) => handleTimeLocationChange(key, value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" error={!!errors[key]} sx={errors[key]
                                                        ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}} />
                                                )}
                                            />
                                            {errors[key] && <StyledInputRequired>Required field</StyledInputRequired>}
                                        </Grid>
                                    </>
                                )}
                                {key === "lanesBlocked" && (
                                    <>
                                        <Grid item xs={5}>
                                            <StyledLabel>Lane(s) Blocked<em style={{ color: 'red' }}> *</em></StyledLabel>
                                            <AdditionalInfoLabel>Select one or more</AdditionalInfoLabel>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <StyledAutocomplete
                                                multiple
                                                value={data[key] || null}
                                                options={incidentDrpDwns[key]?.sort((a, b) => a.order - b.order) || []}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option?.name || ""}
                                                onChange={(event, value) => handleTimeLocationChange(key, value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" error={!!errors[key]} sx={errors[key]
                                                        ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}} />
                                                )}
                                            />
                                            {errors[key] && <StyledInputRequired>Required field</StyledInputRequired>}
                                        </Grid>
                                    </>
                                )}
                                {key === "travelDirections" && (
                                    <>
                                        <Grid item xs={5}>
                                            <StyledLabel>Travel Direction(s)<em style={{ color: 'red' }}> *</em></StyledLabel>
                                            <AdditionalInfoLabel>Select one or more</AdditionalInfoLabel>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <StyledAutocomplete
                                                multiple
                                                value={data[key] || null}
                                                options={incidentDrpDwns[key] || []}
                                                getOptionLabel={(option) => option}
                                                onChange={(event, value) => handleTimeLocationChange(key, value)}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" error={!!errors[key]} sx={errors[key]
                                                        ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}} />
                                                )}
                                            />
                                            {errors[key] && <StyledInputRequired>Required field</StyledInputRequired>}
                                        </Grid>
                                    </>
                                )}
                                {key === "isCritical" && (
                                    <FormControlLabel
                                        label="Mark incident as critical"
                                        control={
                                            <Checkbox
                                                checked={data[key]}
                                                onChange={(event, value) => handleTimeLocationChange(key, value)}
                                            />
                                        }
                                        sx={{
                                            marginLeft: "25px",
                                            marginTop: "20px"
                                        }}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            )
            }
            {
                dialogType === "vehicleInfo" && (
                    <DialogContent>
                        <Grid container>

                            {/* Type of Assist */}
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>Type of Assist<em style={{ color: 'red' }}> *</em></StyledLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <StyledAutocomplete
                                        value={data['selectedVehicle']["typeOfAssist"] || null}
                                        options={incidentDrpDwns["vehicle"] ? incidentDrpDwns["vehicle"]["typeOfAssist"] : []}
                                        getOptionLabel={(option) => option?.name || ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event, value) => handleVehicleInfoChange("typeOfAssist", value)}
                                        renderInput={(params) =>
                                            <TextField {...params} variant="standard" error={!!errors["typeOfAssist"]} sx={errors["typeOfAssist"]
                                                ? { '& .MuiInputBase-root': { borderColor: 'red' } } : {}} />}
                                    />
                                    {errors["typeOfAssist"] && <StyledInputRequired>Required field</StyledInputRequired>}
                                </Grid>
                            </Grid >

                            {/* Service Provided */}
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>Service Provided<em style={{ color: 'red' }}> *</em></StyledLabel>
                                    <AdditionalInfoLabel>Select one or more</AdditionalInfoLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <Autocomplete
                                        multiple
                                        value={data['selectedVehicle']["serviceProvided"] || null}
                                        onChange={(event, value) => handleVehicleInfoChange("serviceProvided", value)}
                                        options={incidentDrpDwns["vehicle"] ? incidentDrpDwns["vehicle"]["serviceProvided"] : []}
                                        getOptionLabel={(option) => option?.name || ""}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="standard" error={!!errors["serviceProvided"]} sx={errors["serviceProvided"]
                                                ? {
                                                    '& .MuiInputBase-root'
                                                        : { borderColor: 'red' }
                                                } : {}} />
                                        )}
                                    />
                                    {errors["serviceProvided"] && <StyledInputRequired>Required field</StyledInputRequired>}
                                </Grid>
                            </Grid>

                            {/* Gallons Given */}
                            {data && data['selectedVehicle']['serviceProvided']?.length > 0
                                && data['selectedVehicle']['serviceProvided'].map(svc => svc.name.toLowerCase()).includes("fuel") && (
                                    <Grid container spacing={3} sx={{ padding: "14px" }}>
                                        <Grid item xs={5}>
                                            <StyledLabel>Gallons Given<em style={{ color: 'red' }}> *</em></StyledLabel>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                variant="outlined"
                                                type="number"
                                                label="Enter gallons given"
                                                value={isNaN(data['selectedVehicle']["gallonsGiven"])
                                                    ? 0.00
                                                    : parseFloat(data['selectedVehicle']["gallonsGiven"])}
                                                onChange={(event) => {
                                                    const floatValue = parseFloat(event.target.value);
                                                    handleVehicleInfoChange("gallonsGiven", floatValue)
                                                }}
                                                error={!!errors["gallonsGiven"]}
                                                fullWidth
                                            />
                                            {errors["gallonsGiven"] && <StyledInputRequired>Required field (value must be greater than 0)</StyledInputRequired>}
                                        </Grid>
                                    </Grid>
                                )}

                            {/* Comments */}
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>Comments<em style={{ color: 'red' }}> *</em></StyledLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        fullWidth
                                        label="Add comments"
                                        value={data['selectedVehicle']["comments"] || ""}
                                        onChange={(event) => handleVehicleInfoChange("comments", event.target.value)}
                                        error={!!errors["comments"]}
                                    />
                                    {errors["comments"] && <StyledInputRequired>Required field</StyledInputRequired>}
                                </Grid>
                            </Grid>

                            {/* Color
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>Color</StyledLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <Autocomplete
                                        value={data['selectedVehicle']["color"] || null}
                                        options={incidentDrpDwns["vehicle"] ? incidentDrpDwns["vehicle"]["color"] : []}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, value) => handleVehicleInfoChange("color", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select a value"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            {/* Make
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>Make</StyledLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <Autocomplete
                                        value={data['selectedVehicle']["make"] || null}
                                        options={incidentDrpDwns["vehicle"] ? incidentDrpDwns["vehicle"]["make"] : []}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, value) => handleVehicleInfoChange("make", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select a value"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            {/* Model
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>Model</StyledLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <Autocomplete
                                        value={data['selectedVehicle']["model"] || null}
                                        options={incidentDrpDwns["vehicle"] ? incidentDrpDwns["vehicle"]["model"] : []}
                                        getOptionLabel={(option) => option}
                                        onChange={(event, value) => handleVehicleInfoChange("model", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select a value"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>

                            {/* Year 
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>Year</StyledLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <Autocomplete
                                        value={data['selectedVehicle']["year"] || null}
                                        freeSolo
                                        options={incidentDrpDwns["vehicle"] ? incidentDrpDwns["vehicle"]["year"] : []}
                                        getOptionLabel={(option) => String(option)}
                                        onChange={(event, value) => handleVehicleInfoChange("year", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Enter or select a value"
                                            />
                                        )}
                                        onInputChange={(event, value) => handleVehicleInfoChange("year", value)}
                                    />
                                </Grid>
                            </Grid>*/}

                            {/* License Plate Number */}
                            <Grid container spacing={3} sx={{ padding: "14px" }}>
                                <Grid item xs={5}>
                                    <StyledLabel>License Plate</StyledLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        variant="outlined"
                                        label="Enter license plate number"
                                        value={data['selectedVehicle']["licensePlateNum"] || ""}
                                        onChange={(event) => handleVehicleInfoChange("licensePlateNum", event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent >
                )
            }
            {
                dialogType === "images" && (
                    <>
                        <DialogContent>
                            {data?.length > 0 && (
                                <>
                                    {data.map((imgs, index) => (
                                        <React.Fragment key={index}>
                                            <Grid container sx={{ paddingLeft: 2, paddingTop: 2 }}>
                                                <Grid item xs={12} >
                                                    <Autocomplete
                                                        value={data[index]?.[0]?.type || null}
                                                        options={incidentDrpDwns["imageTypes"] || []}
                                                        getOptionLabel={(option) => option || ""}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        onChange={(event, value) => handleTypeChange(value, index)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" fullWidth />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ paddingLeft: 1 }}>

                                                {/* Image preview */}
                                                <Grid item xs={8}>
                                                    <Tooltip title="Click to preview image(s)">
                                                        <div>
                                                            <StyledDialogButton
                                                                disabled={imgs.length === 0 || (imgs.length === 1 && !imgs[0]?.file)}
                                                                onClick={() => { setPreviewOpen(true); setPreviewIndex(index) }}>
                                                                {imgs.length === 0 || (imgs.length === 1 && !imgs[0]?.file) ? 'No file(s) uploaded'
                                                                    : `${imgs.length} file(s) uploaded`}
                                                            </StyledDialogButton>
                                                        </div>
                                                    </Tooltip>
                                                </Grid>

                                                {/* Upload image(s) button */}
                                                <Grid item xs={2}>
                                                    <input
                                                        accept="image/*"
                                                        capture="environment"
                                                        style={{ display: 'none' }}
                                                        id={`upload-img-${index}`}
                                                        type="file"
                                                        multiple
                                                        onChange={(event) => handleFileChange(event, index)}
                                                    />
                                                    <label htmlFor={`upload-img-${index}`}>
                                                        <Tooltip title="Click to upload image">
                                                            <IconButton component="span" aria-label="upload image">
                                                                <UploadIcon style={{ color: "#1976d2" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </label>
                                                </Grid>

                                                {/* Delete image(s) button */}
                                                <Grid item xs={2}>
                                                    <Tooltip title="Delete uploaded images">
                                                        <IconButton
                                                            onClick={() => { setIsDialogOpen(true); setDeleteIndex(index); }}
                                                            aria-label="delete image"
                                                            disabled={imgs.length === 0}>
                                                            <DeleteIcon style={{ color: imgs.length === 0 ? 'rgba(0, 0, 0, 0.26)' : '#d32f2f' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                        </DialogContent>

                        {/* Delete Confirmation Dialog */}
                        <ConfirmationDialog
                            open={isDialogOpen}
                            title="Delete Confirmation"
                            content="Are you sure you want to delete uploaded image(s)?"
                            onConfirm={() => {
                                data[deleteIndex] = [];
                                setDeleteIndex(null);
                                setIsDialogOpen(false)
                            }}
                            onClose={() => { setIsDialogOpen(false) }}
                        />

                        {/* Preview image Dialog */}
                        <Dialog open={previewOpen} onClose={() => { setPreviewOpen(false) }}>
                            <StyledDialogTitle>Preview</StyledDialogTitle>
                            <DialogContent>
                                {data[previewIndex]?.length > 0 && data[previewIndex].map((image, index) => {
                                    return (
                                        <img key={index}
                                            src={image.blobURL || image.previewUrl || ''}
                                            alt={`Preview image ${index + 1}`}
                                            style={{ width: '100%', marginBottom: '10px' }} />
                                    );
                                })}
                            </DialogContent>
                            <DialogActions>
                                <StyledDialogButton onClick={() => { setPreviewOpen(false) }}>
                                    Close
                                </StyledDialogButton>
                            </DialogActions>
                        </Dialog>
                    </>
                )
            }
            {dialogType === "closingComments" && (
                <DialogContent>
                    <Grid item xs={12} sx={{ padding: "14px" }}>
                        <TextField
                            multiline
                            minRows={8} // Minimum height of the TextField
                            maxRows={Infinity} // Allow unlimited expansion
                            variant="outlined"
                            fullWidth
                            label="Add comments"
                            value={data.closingComments || ""}
                            onChange={(event) => handleClosingCommentsChange("closingComments", event.target.value)}
                        />
                    </Grid>
                </DialogContent>
            )
            }
            <DialogActions>
                <StyledDialogButton onClick={() => onClose(null)}>Close</StyledDialogButton>
                <StyledDialogButton onClick={() => handleSave()}>Save</StyledDialogButton>
            </DialogActions>
        </Dialog >
    );
}
