import { useState, useEffect, useRef } from 'react';
import { saveRequest } from '../db';

const useLocation = (setValue, getValues) => {
    const [showLocation, setShowLocation] = useState(false);
    const currentLatitudeRef = useRef(null);
    const currentLongitudeRef = useRef(null);

    useEffect(() => {
        if (!currentLatitudeRef.current && !currentLongitudeRef.current && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setValue('location.latitude', latitude);
                    setValue('location.longitude', longitude);
                    saveRequest(
                        "/patroller/postAddIncident",
                        getValues(),
                        "pi0",
                        "tempFormData"
                    );
                    currentLatitudeRef.current = latitude;
                    currentLongitudeRef.current = longitude;
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        }
    }, [setValue, getValues]);

    useEffect(() => {
        if (currentLatitudeRef.current && currentLongitudeRef.current) {
            setShowLocation(true);
        }
    }, [currentLatitudeRef.current, currentLongitudeRef.current]);

    return [showLocation, setShowLocation, currentLatitudeRef, currentLongitudeRef];
};

export default useLocation;