import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import {
    GridActionsCellItem
} from "@mui/x-data-grid";
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";

//components
import PageTitle from "../../components/Titles/PageTitle";
import { getFormattedDateTime } from "../../utils/GetFormattedDateTimeDay";
import CustomToolbar from "../../components/CustomDataGridOptions/CustomToolbar";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";
import { ConfirmationDialog } from "../../components/Dialog/ConfirmationDialog";

//context
import { useDispatcherData } from "../../context/DispatcherContext";

//themes
import themes from "../../theme/defaultTheme";

export default function ViewAllCompleteIncidents() {
    const { StyledCard, StyledGrid, StyledCardContent, StyledDataGrid, StyledChip, StyledBox } = useStyles();
    const { incidentsData, updateDispatcherData, loading } = useDispatcherData();
    const [selectedRow, setSelectedRow] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [mappingLoad, setMappingLoad] = useState(false);
    const navigate = useNavigate();

    const completeIncidents = useMemo(() => {
        if (incidentsData?.length > 0) {
            setMappingLoad(true);
            const currentDateTime = new Date();
            const currentDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate());
            const startTime = new Date(currentDate);
            startTime.setHours(5, 0, 0, 0); //set to 5am
            const endTime = new Date(currentDate);
            endTime.setHours(23, 59, 59, 59); //set to midnight

            //filter by status and lastModifiedOn
            const filteredIncidents = incidentsData.filter(incident => {
                const status = incident.status.toLowerCase();
                if (!incident.lastModifiedOn) return false;
                const lastModifiedDate = new Date(incident.lastModifiedOn);
                return (status === "complete" || status === "closed")
                    && (lastModifiedDate >= startTime && lastModifiedDate <= endTime);
            });
            const mappedIncidents = filteredIncidents?.map(incident => ({
                ...incident,
                "truckNumber": incident.shiftInfo?.truckNumber || "",
                //"phoneNumber": incident.shiftInfo?.phoneNumber || "",
                "loggedinPatroller": incident.shiftInfo?.loggedInPatroller || "",
                "otherPatrollers": incident.shiftInfo?.otherPatrollers?.join(", ") || "",
                "route": incident.location?.route?.name || "",
                "freeway": incident.location?.freeway?.name || "",
                "crossStreet": incident.location?.crossStreet?.name || "",
                "f1017": incident?.f1017 ? getFormattedDateTime(new Date(incident?.f1017)) : "",
                "startTime": incident.time?.startTime ? getFormattedDateTime(new Date(incident.time?.startTime)) : "",
                "endTime": incident.completedOn ? getFormattedDateTime(new Date(incident.completedOn)) : "",
                "lastModifiedOn": incident.lastModifiedOn ? getFormattedDateTime(new Date(incident.lastModifiedOn)) : "",
                "status": incident.status
            }))
            setMappingLoad(false);
            return mappedIncidents;
        } else {
            return []
        }
    }, [incidentsData])

    let columns = [

        //Show actions
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 120,
            getActions: (params) => {
                const incidentId = params.row.id;
                const actions = [];
                actions.push(
                    <GridActionsCellItem
                        aria-label="Edit Action"
                        icon={<IconsWithTooltip title="Click to edit Incident" icon={<EditIcon sx={{ color: themes.default.palette.primary.contrastText }} />} />}
                        label="Edit"
                        onClick={() => { navigate('/dispatcher/edit-incident', { state: { incidentId } }) }}
                    />
                );
                actions.push(
                    <GridActionsCellItem
                        aria-label="Delete Action"
                        icon={<IconsWithTooltip title="Click to delete Incident" icon={<DeleteIcon sx={{ color: themes.default.palette.primary.contrastText }} />} />}
                        label="Delete"
                        onClick={() => {
                            setIsDialogOpen(true);
                            setSelectedRow([params.row.id]);
                        }}
                    />
                )
                return actions;
            }
        },

        //Show truck number
        {
            field: "status",
            headerName: "Status",
            width: 100
        },

        //Show truck number
        {
            field: "truckNumber",
            headerName: "Truck #",
            width: 120
        },

        /*Show phone number
        {
            field: "phoneNumber",
            headerName: "Phone #",
            width: 140
        },*/

        // Show loggedin patroller
        {
            field: "loggedinPatroller",
            headerName: "Primary Patroller",
            width: 180
        },

        //Show other patroller(s)
        {
            field: "otherPatrollers",
            headerName: "Other Patroller(s)",
            width: 200,
            renderCell: (params) => (
                <StyledBox>
                    <Typography variant="body2">{params.value}</Typography>
                </StyledBox>
            )
        },

        //Show route.
        {
            field: "route",
            headerName: "Route",
            width: 180,
        },

        //Show freeway.
        {
            field: "freeway",
            headerName: "Freeway",
            width: 180
        },

        //Show cross street.
        {
            field: "crossStreet",
            headerName: "Cross Street",
            width: 200
        },

        //Show 10-17 time
        {
            field: "f1017",
            headerName: "10-17",
            width: 140,
            filterable: false,
            sortComparator: (a, b) => (a ? new Date(a) : null) - (b ? new Date(b) : null),
            renderCell: (params) => (
                <StyledBox>
                    <Typography variant="body2">{params.value}</Typography>
                </StyledBox>
            )
        },

        //Show incident start time
        {
            field: "startTime",
            headerName: "Start Time",
            width: 140,
            filterable: false,
            sortComparator: (a, b) => (a ? new Date(a) : null) - (b ? new Date(b) : null),
            renderCell: (params) => (
                <StyledBox>
                    <Typography variant="body2">{params.value}</Typography>
                </StyledBox>
            )
        },

        //Show incident end time
        {
            field: "endTime",
            headerName: "End Time",
            width: 140,
            filterable: false,
            sortComparator: (a, b) => (a ? new Date(a) : null) - (b ? new Date(b) : null),
            renderCell: (params) => (
                <StyledBox>
                    <Typography variant="body2">{params.value}</Typography>
                </StyledBox>
            )
        },

        //Show incident last modified time
        {
            field: "lastModifiedOn",
            headerName: "Last Modified",
            width: 140,
            filterable: false,
            sortComparator: (a, b) => (a ? new Date(a) : null) - (b ? new Date(b) : null),
            renderCell: (params) => (
                <StyledBox>
                    <Typography variant="body2">{params.value}</Typography>
                </StyledBox>
            )
        }
    ];

    const handleDeleteConfirm = async () => {
        if (selectedRow?.length > 0) {
            await updateDispatcherData({
                id: selectedRow[0]
            }, "delete")
            setSelectedRow([]);
        }
    }

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* View open incidents link */}
                    <StyledGrid item xs={12} sm={5} md={5} lg={5}>
                        <StyledChip label="Incident Management" onClick={() => navigate('/dispatcher/incident-management')} />
                    </StyledGrid>

                    {/* Page Title */}
                    <Grid item xs={12} sm={7} md={7} lg={7} sx={{ display: 'flex', alignItems: "center" }}>
                        <PageTitle aria-label="View Complete/Closed Incidents" title="View Complete/Closed Incidents" />
                    </Grid>

                    {loading || mappingLoad ? (
                        <StyledGrid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        </StyledGrid>
                    ) : (
                        <StyledGrid container sx={{ paddingTop: "0px" }}>
                            <StyledDataGrid
                                aria-label="Incidents Data Table"
                                rows={completeIncidents}
                                columns={columns}
                                autoHeight
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 10 },
                                    },
                                }}
                                pageSizeOptions={[10, 15, 20, 25]}
                                slots={{
                                    toolbar: CustomToolbar
                                }}
                                slotProps={{
                                    toolbar: { recordsLength: completeIncidents.length }
                                }}
                            />
                        </StyledGrid>)}
                </Grid>

                {/* Delete Confirmation Dialog */}
                <ConfirmationDialog
                    open={isDialogOpen}
                    title="Delete Confirmation"
                    content="Are you sure you want to delete selected incident?"
                    onConfirm={handleDeleteConfirm}
                    onClose={() => { setIsDialogOpen(false) }}
                />
            </StyledCardContent>
        </StyledCard >
    )
}