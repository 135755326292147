import React, { useState, useLayoutEffect, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import {
  Stack,
  CardContent,
  TextField,
  Grid,
  Typography,
  Autocomplete,
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Tooltip,
  IconButton
} from "@mui/material";
import useStyles from "./styles";

// icons
import {
  RemoveCircleOutline as ClearIcon,
  AddCircleOutline as AddIcon,
  AlarmAddOutlined as SetTimeIcon
} from "@mui/icons-material";

//components
import PageTitle from "../Titles/PageTitle";
import SectionTitle from "../Titles/SectionTitle";
import {
  getFormattedDate,
  getCustomDay,
  getFormattedTime
} from "../../utils/GetFormattedDateTimeDay";
import CloseIncidentDialog from "../Dialog/CloseIncidentDialog";
import { ConfirmationDialog } from "../Dialog/ConfirmationDialog";

//context
import { useDispatcherData } from "../../context/DispatcherContext";
import { useAuth } from "../../context/AuthContext";

//constants
import { ApiEndpoints } from "../../utils/Constants";

//custom hooks
import { useFetch } from "../../hooks/useFetch";

//themes
import themes from "../../theme/defaultTheme";

export const AddIncidentForm = ({ dropDownValues, allRoutes }) => {
  const { updateDispatcherData } = useDispatcherData();
  const location = useLocation();
  const { latLng, incidentId } = location.state;
  const {
    StyledCard,
    FormLabel,
    AdditionalInfoLabel,
    StyledButtonGrid,
    StyledButton,
  } = useStyles();
  const methods = useForm();
  const { control, watch, setValue, getValues } = methods;
  const currentDate = new Date();
  const formattedDate = getFormattedDate(currentDate);
  const currentDay = getCustomDay(currentDate);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const getFetcher = useFetch("get");
  const [selectedIncident, setSelectedIncident] = useState({});

  //form elements
  const selectedRoute = watch("route");
  const selectedFreeway = watch("freeway");

  //React hooks
  const [showAlert] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDelDialogOpen, setIsDelDialogOpen] = useState(false);
  const [vehicles, setVehicles] = useState([
    {
      //make: "",
      //model: "",
      //year: "",
      //color: "",
      licensePlateNum: "",
      typeOfAssist: "",
      serviceProvided: "",
      comments: "",
    },
  ]);
  const [vehicleToDel, setVehicleToDel] = useState();
  const [loadBtn, setLoadBtn] = useState(false);
  const [svcIsFuel, setSvcIsFuel] = useState([]);
  const [callInTime, setCallInTime] = useState('');
  const [f1017, setF1017] = useState('');

  //Drop Down values
  const routeOptions = useMemo(() => {
    return allRoutes?.length > 0
      ? allRoutes.map((route) => ({
        id: route.id,
        name: route.name,
      }))
      : [];
  }, [allRoutes]);

  const freewayOptions = useMemo(() => {
    if (selectedRoute && allRoutes?.length > 0) {
      const selectedRouteData = allRoutes.find(
        (route) => route.id === selectedRoute.id
      );
      return (
        selectedRouteData?.freeways?.map((fwy) => ({
          id: fwy.id,
          name: fwy.name,
        })) || []
      );
    }
    return [];
  }, [selectedRoute, allRoutes]);

  const crossStreetOptions = useMemo(() => {
    if (selectedRoute && selectedFreeway && allRoutes?.length > 0) {
      const selectedRouteData = allRoutes.find(
        (route) => route.id === selectedRoute.id
      );
      const selectedFreewayData = selectedRouteData?.freeways.find(
        (fwy) => fwy.id === selectedFreeway.id
      );
      return (
        selectedFreewayData?.crossStreets?.map((street) => ({
          id: street.id,
          name: street.name,
        })) || []
      );
    }
    return [];
  }, [selectedRoute, selectedFreeway, allRoutes]);

  const detectMethodOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues.detectMethods || [];
  }, [dropDownValues]);

  const lanesBlockedOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return (dropDownValues.lanesBlocked || []).sort((a, b) => a.order - b.order);
  }, [dropDownValues]);

  const travelDirectionOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues.travelDirections || [];
  }, [dropDownValues]);

  /*const makeOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues["vehicle"]["make"] || [];
  }, [dropDownValues]);

  const modelOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues["vehicle"]["model"] || [];
  }, [dropDownValues]);

  const yearOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues["vehicle"]["year"] || [];
  }, [dropDownValues]);

  const colorOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues["vehicle"]["color"] || [];
  }, [dropDownValues]);*/

  const typeOfAssistOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues["vehicle"]["typeOfAssist"] || [];
  }, [dropDownValues]);

  const serviceProvidedOptions = useMemo(() => {
    if (dropDownValues && Object.keys(dropDownValues).length > 0)
      return dropDownValues["vehicle"]["serviceProvided"] || [];
  }, [dropDownValues]);

  //when close incident is clicked
  const handleSubmitDialog = async (comments) => {
    const formValues = getValues();
    let mode = "open";

    //close dialog
    setIsDialogOpen(false);

    const newIncident = {
      shiftId: null,
      closingComments: comments,
      status: "closed",
      location: {
        freeway: formValues.freeway || {},
        crossStreet: formValues.crossStreet || {},
        route: formValues.route || {},
        travelDirection: formValues.travelDirection || [],
        lanesBlocked: formValues.lanesBlocked || [],
        latitude: latLng ? latLng.lat : 0,
        longitude: latLng ? latLng.lng : 0,
      },
      callInTime: formValues.callInTime || "",
      f1017: formValues.f1017 || "",
      time: {
        date: formValues.date || "",
        startTime: "",
        dayOfWeek: formValues.dayOfWeek || "",
        detectMethod: formValues.detectMethod || "",
      },
      images: [],
      isCritical: formValues.isCritical,
    };

    if (formValues?.vehicles?.length > 0) {
      const formVehicles = [];
      formValues.vehicles.map((vehicle) => {
        if (
          //vehicle.make ||
          //vehicle.model ||
          //vehicle.year ||
          //vehicle.color ||
          vehicle.licensePlateNum ||
          vehicle.comments
        )
          formVehicles.push(vehicle);
      });
      newIncident.vehicles = formVehicles;
    } else newIncident.vehicles = [];

    //for edit
    if (selectedIncident && Object.keys(selectedIncident)?.length > 0) {
      newIncident.id = selectedIncident.id;
      newIncident.location.latitude = selectedIncident.location.latitude;
      newIncident.location.longitude = selectedIncident.location.longitude;
      mode = "edit";
    }

    //add to database
    await updateDispatcherData(newIncident, mode);

    setTimeout(() => {
      navigate("/dispatcher/incident-management");
    }, 3000); //sleep for 3 seconds
  };

  const handleRouteChange = (event, value) => {
    // Clear the form fields
    setValue("freeway", null);
    setValue("crossStreet", null);
    setValue("route", value);
  };

  const handleFreewayChange = (event, value) => {
    // Clear the form fields
    setValue("crossStreet", null);
    setValue("freeway", value);
  };

  //Function called when add another vehicle button is clicked
  const handleAddVehicle = () => {
    setVehicles((prev) => [
      ...prev,
      {
        //make: "",
        //model: "",
        //year: "",
        //color: "",
        licensePlateNum: "",
        typeOfAssist: "",
        serviceProvided: "",
        comments: "",
      },
    ]);
  };

  //Function called when delete vehicle button is clicked
  const handleDeleteVehicle = () => {
    const vehiclesLst = [...getValues("vehicles")];
    vehiclesLst.splice(vehicleToDel, 1);
    setValue("vehicles", vehiclesLst);
    setVehicles(vehiclesLst);

    //update svcIsFuel as per vehiclesLst
    setSvcIsFuel((prevSvcIsFuel) => {
      const indexList = [];
      vehiclesLst.forEach((vehicle, index) => {
        if (
          vehicle?.serviceProvided
            ?.map((svc) => svc?.name?.toLowerCase())
            .includes("fuel")
        )
          indexList.push(index);
      });
      return [...indexList];
    });

    //close delete dialog
    setIsDelDialogOpen(false);
  };

  //Function called when open incident button is clicked
  const handleEditOpenIncident = async (mode) => {
    setLoadBtn(true);
    const formValues = getValues();
    let newIncident = {};

    if (formValues && Object.keys(formValues).length > 0) {
      newIncident = {
        location: {
          freeway: formValues.freeway || {},
          crossStreet: formValues.crossStreet || {},
          route: formValues.route || {},
          travelDirection: formValues.travelDirection || [],
          lanesBlocked: formValues.lanesBlocked || [],
          latitude: latLng ? latLng.lat : 0,
          longitude: latLng ? latLng.lng : 0,
        },
        callInTime: formValues.callInTime || "",
        f1017: formValues.f1017 || "",
        time: {
          date: formValues.date || "",
          startTime: "",
          dayOfWeek: formValues.dayOfWeek || "",
          detectMethod: formValues.detectMethod || "",
        },
        images: [],
        isCritical: formValues.isCritical,
        status: "open",
      };
    }

    if (formValues?.vehicles?.length > 0) {
      const formVehicles = [];
      formValues.vehicles.map((vehicle) => {
        if (
          //vehicle.make ||
          //vehicle.model ||
          //vehicle.year ||
          //vehicle.color ||
          vehicle.licensePlateNum ||
          vehicle.serviceProvided ||
          vehicle.typeOfAssist ||
          vehicle.gallonsGiven ||
          vehicle.comments
        )
          formVehicles.push(vehicle);
      });
      newIncident.vehicles = formVehicles;
    } else newIncident.vehicles = [];

    //Save or add data as per mode.
    if (mode === "edit" && incidentId) {
      newIncident.id = selectedIncident.id;
      newIncident.location.latitude = selectedIncident.location.latitude;
      newIncident.location.longitude = selectedIncident.location.longitude;
    }

    //Update database
    await updateDispatcherData(newIncident, mode);

    setTimeout(() => {
      setLoadBtn(false);
      navigate("/dispatcher/incident-management");
    }, 3000); //sleep for 3 seconds
  };

  //Function called when set call in time button is clicked
  const handleSetCallInTimeBtn = () => {
    const currentDate = new Date();
    const currentTime = getFormattedTime(currentDate);
    setCallInTime(currentTime);

    //Set form state
    setValue("callInTime", currentDate);
  }

  //Function called when 10-17 time button is clicked
  const handleSet1017TimeBtn = () => {
    const currentDate = new Date();
    const currentTime = getFormattedTime(currentDate);
    setF1017(currentTime);

    //Set form state
    setValue("f1017", currentDate);
  }

  useLayoutEffect(() => {
    if (vehicles.length === 0) {
      handleAddVehicle();
    }
  }, [vehicles]);

  //For edit incident, pre-populate data
  useEffect(() => {
    if (incidentId) {
      const fetchData = async () => {
        try {
          const response = await getFetcher.setRequest({
            endpoint:
              "/dispatcher" +
              ApiEndpoints["API_GET_INCIDENT_BY_ID"] +
              "?id=" +
              incidentId,
          });
          if (response) {
            if (!response.error && response.data?.incident)
              //if no error
              setSelectedIncident(response.data.incident);
            else if (
              response.error &&
              (response.errorStatus === 403 || response.errorStatus === 401)
            )
              //logout if forbidden or unauthorized
              logout();
          }
        } catch (error) {
          console.error(
            "Error fetching data for incident :",
            incidentId,
            " : ",
            error
          );
        }
      };
      fetchData();
    }
  }, [incidentId]);

  useEffect(() => {
    if (selectedIncident && Object.keys(selectedIncident)?.length > 0) {
      setValue("isCritical", selectedIncident.isCritical);
      if (selectedIncident?.time) {
        let formDate = formattedDate;
        let formDayOfWeek = currentDay;
        if (selectedIncident.time.date) {
          formDate = getFormattedDate(new Date(selectedIncident.time.date));
          formDayOfWeek = getCustomDay(new Date(selectedIncident.time.date));
        }
        setValue("date", formDate);
        setValue("dayOfWeek", formDayOfWeek);
        setValue("detectMethod", selectedIncident.time.detectMethod);
      }
      if (selectedIncident?.callInTime) {
        const callInTime = new Date(selectedIncident.callInTime);
        setCallInTime(getFormattedTime(callInTime));
        setValue("callInTime", callInTime);
      }
      if (selectedIncident?.f1017) {
        const f1017 = new Date(selectedIncident.f1017);
        setF1017(getFormattedTime(f1017));
        setValue("f1017", f1017);
      }
      if (selectedIncident?.location) {
        setValue("route", selectedIncident.location.route);
        setValue("freeway", selectedIncident.location.freeway);
        setValue("crossStreet", selectedIncident.location.crossStreet);
        setValue("travelDirection", selectedIncident.location.travelDirection);
        setValue("lanesBlocked", selectedIncident.location.lanesBlocked);
      }
      if (selectedIncident?.vehicles?.length > 0) {
        setVehicles(selectedIncident.vehicles);
        setValue("vehicles", selectedIncident.vehicles);

        //update svcIsFuel as per vehicles in selectedIncident
        setSvcIsFuel((prevSvcIsFuel) => {
          const indexList = [];
          selectedIncident.vehicles.forEach((vehicle, index) => {
            if (
              vehicle?.serviceProvided
                ?.map((svc) => svc?.name?.toLowerCase())
                .includes("fuel")
            )
              indexList.push(index);
          });
          return [...indexList];
        });
      }
    }
  }, [selectedIncident]);

  return (
    <FormProvider {...methods}>
      <Stack>
        <StyledCard>
          <CardContent>
            {/* Page Title */}
            <Grid item xs={12}>
              {!incidentId && <PageTitle title="Add Incident Details" />}
              {incidentId && <PageTitle title="Edit Incident Details" />}
            </Grid>

            {/* Alert to close an incident */}
            {showAlert && (
              <Grid item xs={12}>
                <Alert
                  severity="info"
                  onClick={() => {
                    setIsDialogOpen(true);
                  }}
                  style={{
                    cursor: "pointer",
                    marginLeft: "17px",
                    marginBottom: "20px",
                  }}
                >
                  Click here to CLOSE incident.
                </Alert>
              </Grid>
            )}

            {/* Form */}
            <Grid item xs={12}>
              <form>
                {/* General Information Section */}
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <SectionTitle
                      title="General Information"
                      isOpenIncident="true"
                    />
                  </Grid>

                  {/* Date Label*/}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Date</FormLabel>
                  </Grid>

                  {/* Date Field*/}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue={formattedDate}
                      render={({ field }) => (
                        <TextField {...field} disabled sx={{ width: "80%" }} />
                      )}
                    />
                  </Grid>

                  {/* Day of week Label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Day of week</FormLabel>
                  </Grid>

                  {/* Day of Week field */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="dayOfWeek"
                      control={control}
                      defaultValue={currentDay}
                      render={({ field }) => (
                        <TextField {...field} disabled sx={{ width: "80%" }} />
                      )}
                    />
                  </Grid>

                  {/* Call in Time Label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Call in Time</FormLabel>
                  </Grid>

                  {/* Call in Time Field */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Grid container>
                      <Grid item xs={9}>
                        <Controller
                          name="callInTime"
                          control={control}
                          defaultValue={callInTime}
                          render={({ field }) => (
                            <TextField {...field} value={callInTime} sx={{ width: "100%" }} disabled />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Tooltip title="Click to set call in time">
                          <IconButton onClick={handleSetCallInTimeBtn}>
                            <SetTimeIcon
                              sx={{
                                color: themes.default.palette.primary.contrastText,
                                marginTop: "6px"
                              }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* 10-17 time Label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>10-17</FormLabel>
                  </Grid>

                  {/* 10-17 time Field */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Grid container>
                      <Grid item xs={9}>
                        <Controller
                          name="f1017"
                          control={control}
                          defaultValue={f1017}
                          render={({ field }) => (
                            <TextField {...field} value={f1017} sx={{ width: "100%" }} disabled />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Tooltip title="Click to set 10-17 time">
                          <IconButton onClick={handleSet1017TimeBtn}>
                            <SetTimeIcon
                              sx={{
                                color: themes.default.palette.primary.contrastText,
                                marginTop: "6px"
                              }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Route Label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Route</FormLabel>
                  </Grid>

                  {/* Route drop down */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="route"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value || null}
                          options={routeOptions || []}
                          getOptionLabel={(option) => option?.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, value) => {
                            handleRouteChange(event, value.id);
                            field.onChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select route"
                              sx={{ width: "80%" }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  {/* Travel Direction(s) label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Travel Direction</FormLabel>
                    <AdditionalInfoLabel>
                      Select one or more
                    </AdditionalInfoLabel>
                  </Grid>

                  {/* Travel Direction(s) multi select drop down */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="travelDirection"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value || []}
                          multiple
                          options={travelDirectionOptions || []}
                          getOptionLabel={(option) => option}
                          onChange={(_, option) => field.onChange(option)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ width: "80%" }}
                              label="Select travel direction(s)"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  {/* Freeway Label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Freeway</FormLabel>
                  </Grid>

                  {/* Freeway drop down */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="freeway"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value || null}
                          multiple={false}
                          options={freewayOptions || []}
                          getOptionLabel={(option) => option?.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, value) => {
                            handleFreewayChange(event, value.id);
                            field.onChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ width: "80%" }}
                              label="Select freeway"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  {/* Cross Street Label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Cross Street</FormLabel>
                  </Grid>

                  {/* Cross Street drop down */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="crossStreet"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value || null}
                          multiple={false}
                          options={crossStreetOptions || []}
                          getOptionLabel={(option) => option?.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(_, value) => field.onChange(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ width: "80%" }}
                              label="Select cross street"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  {/* Lanes Blocked label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Lane(s) Blocked</FormLabel>
                    <AdditionalInfoLabel>
                      Select one or more
                    </AdditionalInfoLabel>
                  </Grid>

                  {/* Lanes Blocked multi select drop down */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="lanesBlocked"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value || []}
                          multiple
                          options={lanesBlockedOptions || []}
                          getOptionLabel={(option) => option?.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(_, value) => field.onChange(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ width: "80%" }}
                              label="Select lane(s) blocked"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  {/* Detect Method label */}
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <FormLabel>Detect Method</FormLabel>
                  </Grid>

                  {/* Detect Method drop down */}
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Controller
                      name="detectMethod"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value || "Dispatch"}
                          multiple={false}
                          options={detectMethodOptions || []}
                          getOptionLabel={(option) => option || ""}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          onChange={(_, value) => field.onChange(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ width: "80%" }}
                              label="Select detect method"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  {/* Mark incident as critical field */}
                  <Grid
                    item
                    xs={12}
                    sx={{ marginLeft: "17px", marginTop: "15px" }}
                  >
                    <Controller
                      name="isCritical"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          }
                          label="Mark incident as critical"
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {/* Vehicle Information Section */}
                {vehicles?.length > 0 &&
                  vehicles.map((vehicle, index) => (
                    <Grid container spacing={4} key={index}>
                      {/* Section Title and clear button */}
                      <Grid item xs={12} style={{ marginTop: "50px" }}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography
                            color="inherit"
                            variant="h5"
                            mt={2}
                            style={{
                              marginTop: "12px",
                              marginRight: "8px",
                              marginLeft: "17px",
                              fontWeight: "bold",
                              color: themes.default.palette.text.primary,
                            }}
                          >
                            {`Vehicle ${index + 1} Information`}
                          </Typography>
                          <StyledButton
                            startIcon={<ClearIcon />}
                            onClick={() => {
                              setIsDelDialogOpen(true);
                              setVehicleToDel(index);
                            }}
                          >
                            Delete Vehicle {index + 1}
                          </StyledButton>
                        </Box>
                        <hr
                          style={{
                            marginTop: "12px",
                            marginRight: "8px",
                            marginLeft: "17px",
                            color: "#1976d2",
                          }}
                        />
                      </Grid>

                      {/* Type of assist Label */}
                      <Grid item xs={6} sm={2} md={2} lg={2}>
                        <FormLabel>Type of Assist</FormLabel>
                      </Grid>

                      {/* Type of assist field */}
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Controller
                          name={`vehicles[${index}].typeOfAssist`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              value={field.value || null}
                              options={typeOfAssistOptions || []}
                              getOptionLabel={(option) => option?.name || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(_, value) => field.onChange(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ width: "80%" }}
                                  label="Select type of assist"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>

                      {/* Service Provided Label */}
                      <Grid item xs={6} sm={2} md={2} lg={2}>
                        <FormLabel>Service Provided</FormLabel>
                        <AdditionalInfoLabel>
                          Select one or more
                        </AdditionalInfoLabel>
                      </Grid>

                      {/* Service Provided field */}
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Controller
                          name={`vehicles[${index}].serviceProvided`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              value={field.value || []}
                              options={serviceProvidedOptions || []}
                              getOptionLabel={(option) => option?.name || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, value) => {
                                field.onChange(value);
                                const isFuelSelected = value
                                  .map((svc) => svc?.name?.toLowerCase())
                                  .includes("fuel");

                                setSvcIsFuel((prevState) => {
                                  if (
                                    isFuelSelected &&
                                    !prevState.includes(index)
                                  ) {
                                    return [...prevState, index];
                                  } else if (
                                    !isFuelSelected &&
                                    prevState.includes(index)
                                  ) {
                                    prevState.splice(index, 1);
                                    return [...prevState];
                                  }
                                  return prevState;
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select service provided"
                                  sx={{ width: "80%" }}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>

                      {/* Show Gallons Given field if service provided is fuel */}
                      {svcIsFuel.includes(index) && (
                        <>
                          {/* Gallons Given Label */}
                          <Grid item xs={6} sm={2} md={2} lg={2}>
                            <FormLabel>Gallons Given</FormLabel>
                          </Grid>

                          {/* Gallons Given field */}
                          <Grid item xs={6} sm={4} md={4} lg={4}>
                            <Controller
                              name={`vehicles[${index}].gallonsGiven`}
                              control={control}
                              rules={{
                                validate: (value) => {
                                  const floatValue = parseFloat(value);
                                  if (isNaN(floatValue)) {
                                    return "Must be a number";
                                  } else if (floatValue <= 0) {
                                    return "Must be greater than 0";
                                  }
                                  return true;
                                },
                              }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  type="number"
                                  label="Enter gallons given"
                                  onChange={(e) => {
                                    const floatValue = parseFloat(
                                      e.target.value
                                    );
                                    setValue(
                                      `vehicles[${index}].gallonsGiven`,
                                      isNaN(floatValue) ? 0.0 : floatValue
                                    );
                                  }}
                                  sx={{ width: "80%" }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}

                      {/* blank grid to move next item to next line */}
                      {svcIsFuel.includes(index) && <Grid item xs={6}></Grid>}

                      {/* Comments Label */}
                      <Grid item xs={12} sm={2} md={2} lg={2}>
                        <FormLabel>Comments</FormLabel>
                      </Grid>

                      {/* Comments field */}
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        md={10}
                        lg={10}
                      >
                        <Controller
                          name={`vehicles[${index}].comments`}
                          control={control}
                          defaultValue={getValues(
                            `vehicles[${index}].comments`
                          )}
                          render={({ field }) => (
                            <TextField
                              multiline
                              rows={2}
                              variant="outlined"
                              fullWidth
                              label="Add comments"
                              style={{ width: "93%" }}
                              value={field.value || ""}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                          )}
                        />
                      </Grid>

                      {/* Color Label
                      <Grid item xs={6} sm={2} md={2} lg={2}>
                        <FormLabel>Color</FormLabel>
                      </Grid>

                      {/* Color field
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Controller
                          name={`vehicles[${index}].color`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              value={field.value || null}
                              options={colorOptions || []}
                              getOptionLabel={(option) => option}
                              onChange={(_, value) => field.onChange(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ width: "80%" }}
                                  label="Select color"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>

                      {/* Make Label
                      <Grid item xs={6} sm={2} md={2} lg={2}>
                        <FormLabel>Make</FormLabel>
                      </Grid>

                      {/* Make field
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Controller
                          name={`vehicles[${index}].make`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              value={field.value || null}
                              options={makeOptions || []}
                              getOptionLabel={(option) => option}
                              onChange={(event, value) => field.onChange(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ width: "80%" }}
                                  label="Select make"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>

                      {/* Model Label
                      <Grid item xs={6} sm={2} md={2} lg={2}>
                        <FormLabel>Model</FormLabel>
                      </Grid>

                      {/* Model field
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Controller
                          name={`vehicles[${index}].model`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              value={field.value || null}
                              options={modelOptions || []}
                              getOptionLabel={(option) => option}
                              onChange={(_, value) => field.onChange(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ width: "80%" }}
                                  label="Select model"
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>

                      {/* Year Label 
                      <Grid item xs={6} sm={2} md={2} lg={2}>
                        <FormLabel>Year</FormLabel>
                      </Grid>

                      {/* Year field
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Controller
                          name={`vehicles[${index}].year`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              value={field.value || null}
                              freeSolo
                              options={yearOptions || []}
                              getOptionLabel={(option) => String(option)}
                              onChange={(_, value) => field.onChange(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ width: "80%" }}
                                  label="Enter or select year"
                                />
                              )}
                              onInputChange={(event, value) =>
                                field.onChange(value)
                              }
                            />
                          )}
                        />
                      </Grid>*/}

                      {/* License Plate Label */}
                      <Grid item xs={6} sm={2} md={2} lg={2}>
                        <FormLabel>License Plate</FormLabel>
                      </Grid>

                      {/* License Plate field */}
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Controller
                          name={`vehicles[${index}].licensePlateNum`}
                          control={control}
                          defaultValue={getValues(
                            `vehicles[${index}].licensePlateNum`
                          )}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              label="Enter license plate #"
                              value={field.value || ""}
                              onChange={(e) => field.onChange(e.target.value)}
                              sx={{ width: "80%" }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  ))}

                {/* Add Another Vehicle Button */}
                <Grid item xs={12} style={{ textAlign: "center", marginTop: "28px" }}>
                  <StyledButton
                    onClick={handleAddVehicle}
                    startIcon={<AddIcon />}
                  >
                    Add another vehicle
                  </StyledButton>
                </Grid>
              </form>
            </Grid>

            {/* Open Incident or Save Button */}
            <StyledButtonGrid item xs={12} style={{ textAlign: "center" }}>
              {loadBtn && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress aria-label="loading indicator" />
                </Box>
              )}
              {!incidentId && !loadBtn && (
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    handleEditOpenIncident("open");
                  }}
                >
                  Open Incident
                </StyledButton>
              )}
              {incidentId && !loadBtn && (
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    handleEditOpenIncident("edit");
                  }}
                >
                  Save
                </StyledButton>
              )}
            </StyledButtonGrid>
          </CardContent>

          {/* Close Incident Dialog */}
          <CloseIncidentDialog
            open={isDialogOpen}
            onClose={() => {
              setIsDialogOpen(false);
            }}
            onSubmit={handleSubmitDialog}
            title="Close Incident"
          />

          {/* Delete Confirmation Dialog */}
          <ConfirmationDialog
            open={isDelDialogOpen}
            title="Delete Confirmation"
            content="Are you sure you want to delete selected vehicle?"
            onConfirm={handleDeleteVehicle}
            onClose={() => {
              setIsDelDialogOpen(false);
            }}
          />
        </StyledCard>
      </Stack>
    </FormProvider>
  );
};
